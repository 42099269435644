import { Injectable } from "@angular/core";
import { catchError, tap } from "rxjs/operators";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from "@angular/common/http";
import { EMPTY, EmptyError, observable, Observable, throwError } from "rxjs";
import { DataService } from "../service/data/data.service";
import { ActivatedRoute, Router } from "@angular/router";

@Injectable()
export class MyInterceptor implements HttpInterceptor {
  constructor(private dataService: DataService, private router: Router) { }
  //function which will be called for all http calls
  intercept(request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const url = this.router.url;
    const segments = url.split('/');
    const firstPosition = segments[1];
    let user: any = localStorage.getItem("user")
    let userData = JSON.parse(user)
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (userData) {
      if (request.body instanceof FormData) {
        request = request.clone({
          setHeaders: {
            'Bearer': `${userData.token}`,
            'user-id': `${userData.id}`,
            'timezone': timezone
          }
        })
      }
      else {
        request = request.clone({
          setHeaders: {
            'Bearer': userData.token,
            'user-id': userData.id,
            'timezone': timezone,
            'language': 'en'
          },
        });
      }

    }


    return next.handle(request).pipe(

      tap(() => { }

        , error => {

          //logging the http response to browser's console in case of a failuer
          if (error instanceof HttpErrorResponse) {
            if ((error.status == 401 || !userData) && firstPosition != 'auth') {
              this.router.navigateByUrl('auth/login').then(() => {
                window.location.reload();
              });
              this.dataService.customSnackBar('Please login again', 'Something went wrong', 'error');

            }
          }
        }
      ),
      catchError((err: any) => {
        // error = err;
        // TODO: error handling if required
        if (firstPosition != 'auth' && !userData) {
          return EMPTY;
        }
        else {
          throw err;

        }
      })
    )
  }
}
