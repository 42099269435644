import { JsonPipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as saveAs from 'file-saver';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SnackbarComponent } from 'src/app/core/snackbar/snackbar.component';
import { environment } from 'src/environments/environment';
import { dashboard } from '../../models/dashboard';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  $baseURL = environment.baseUrl
  currentUserData: any
  imageData: any[] = []
  constructor(public http: HttpClient, public snackBar: MatSnackBar) {
  }
  public resultData = new Subject<any>();
  public userFormChanges = new Subject<any>();
  public openProfileBar = new Subject<any>();
  public exportIds = new Subject<any>();
  public totalExportId = new Subject<any>();

  private dataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public data$: Observable<any> = this.dataSubject.asObservable();
  private studentDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public studentData$: Observable<any> = this.studentDataSubject.asObservable();


  private tableSavedDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public tableSavedData$: Observable<any> = this.tableSavedDataSubject.asObservable();

  setData(data: any): void {
    this.dataSubject.next(data);
  }
  setStudentData(data: any): void {
    this.studentDataSubject.next(data);
  }


  saveTableData(data: any): void {
    this.tableSavedDataSubject.next(data);
  }

  clearImageData() {
    this.imageData = []
  }

  updateExportIDArray(newId: any) {
    this.exportIds.next(newId)
  }

  updateExportIDLength(length: any) {
    this.totalExportId.next(length)
  }

  pushImageData(data: any) {
    if (data?.name != undefined) {
      this.imageData = []
      this.imageData.push(data)
    }
  }

  // user dynamic  form completion  status change
  formStatusChange(formValue: any) {
    this.userFormChanges.next(formValue)
  }

  // open profile bar
  profileBarStatusChange(formValue: any) {
    this.openProfileBar.next(formValue)
  }


  //user detail data change
  userDataChange(userData: any) {
    this.currentUserData = userData
    this.resultData.next(userData)
  }

  //API for Update Values
  patchUserDetail(data: any, type: any) {
    let user
    if (type == 2) user = 'student'
    else if (type == 3) user = 'teacher'
    else if (type == 4) user = 'doctor'
    let userDetail = localStorage.getItem('user') || ''
    let userid = userDetail ? JSON.parse(userDetail) : ''
    return this.http.patch(this.$baseURL +
      "/tour/" + user + "/" + userid.id + "/", data)
  }

  //API for get Information of onboarding
  userDetail(type: number): Observable<dashboard> {
    const data = localStorage.getItem('user');
    const user = data ? JSON.parse(data) : { id: '' };
    const userType = type === 2 ? 'student' : type === 3 ? 'teacher' : 'doctor';
    return this.http.get<dashboard>(`${this.$baseURL}/tour/${userType}/${user.id}/`);
  }


  // studentDetail get API
  studentDetail(data: any) {
    return this.http.get(this.$baseURL +
      "/tour/studentlist/" + data + "/")
  }

  //API for get Country
  country() {
    return this.http.get(this.$baseURL +
      "/accounts/countries/")
  }

  //api for Reset Password
  resetPswd(data: any) {
    let user = localStorage.getItem('user') || ''
    let userid = user ? JSON.parse(user) : ''
    return this.http.patch(this.$baseURL +
      "/accounts/" + userid.id + "/password/reset/", data)
  }


  //api for inviting user
  inviteUser(data: any) {
    return this.http.post(this.$baseURL +
      "/accounts/users/", data)
  }

  // api for Group Listing
  groupList(filterdata: any) {
    const httpOptions = {
      params: new HttpParams({ fromObject: filterdata }),
    };
    return this.http.get(this.$baseURL +
      "/tour/groups/", httpOptions)
  }

  getFolders(params: any) {
    const httpOptions = {
      params: new HttpParams({ fromObject: params }),
    };
    return this.http.get(this.$baseURL +
      "/tour/folder/", httpOptions)
  }

  // api for  Group Details
  groupDetails(id: any) {
    return this.http.get(this.$baseURL +
      "/tour/groups/" + id + "/")
  }

  Detailpatch(data: any, id: any) {
    return this.http.patch(this.$baseURL +
      "/tour/groups/" + id + "/", data)
  }

  // api for filtered Group Name
  groupName(filter: any) {
    const httpOptions = {
      params: new HttpParams({ fromObject: filter }),
    };
    return this.http.get(this.$baseURL +
      "/tour/groups/", httpOptions)
  }

  // api for filtered Group Name in sponsor section
  getGroupNames(filter: any) {
    const httpOptions = {
      params: new HttpParams({ fromObject: filter }),
    };
    return this.http.get(this.$baseURL +
      "/tour/group/base/", httpOptions)
  }

  //delete group , folder , school , agenda
  deleteGroup(id: any, data: any) {
    return this.http.delete(this.$baseURL +
      "/tour/" + data + "/" + id + "/")
  }


  // api for User Teacher
  userList(options: any, user: any) {
    const httpOptions = {
      params: new HttpParams({ fromObject: options }),
    };
    return this.http.get(this.$baseURL +
      "/tour/" + user + "/", httpOptions)
  }

  //delete Group api
  deleteUser(data: any) {
    return this.http.delete(this.$baseURL +
      "/accounts/users/" + data + "/")
  }

  //delete student api
  deleteStudent(data: any) {
    return this.http.delete(this.$baseURL +
      "/tour/student/" + data + "/")
  }



  // api for filtered school list
  schoolList(filterdata: any) {
    const httpOptions = {
      params: new HttpParams({ fromObject: filterdata }),
    };
    return this.http.get(this.$baseURL +
      "/tour/school/", httpOptions)
  }

  //viewfile
  viewFile(id: any, filter: any) {
    const httpOptions = {
      params: new HttpParams({ fromObject: filter }),
    };
    return this.http.get(this.$baseURL + '/tour/files/?folder=' + id, httpOptions)
  }
  //Create Agenda api
  addAgenda(data: any, type: any, id: any) {
    if (type == 1) {
      return this.http.post(this.$baseURL +
        "/tour/agenda/", data)
    }
    else {
      return this.http.patch(this.$baseURL +
        "/tour/agenda/" + id + "/", data)
    }
  }

  // get Agenda api
  getAgenda(id: any, filter: any) {
    const httpOptions = {
      params: new HttpParams({ fromObject: filter }),
    };
    return this.http.get(this.$baseURL +
      '/tour/agenda/?group=' + id, httpOptions)
  }

  //get student list
  getStudentList(data: any) {
    const httpOptions = {
      params: new HttpParams({ fromObject: data }),
    };
    return this.http.get(this.$baseURL +
      '/tour/student/', httpOptions)
  }


  //get sponser list
  sponserList(id: any, filter: any) {
    const httpOptions = {
      params: new HttpParams({ fromObject: filter }),
    };
    return this.http.get(this.$baseURL +
      '/finance/sponser/?code=' + id, httpOptions)
  }

  //to get chapter list
  getchapter(id: any , version?:string) {
    return this.http.get(this.$baseURL +
      "/dynamic-form/chapter/?form=" + id + "&version=" + version)
  }

  //get chapter field value
  getChaptersFieldData(submissionId: any, chapterId: any) {
    return this.http.get(this.$baseURL +
      "/dynamic-form/submission/" + submissionId + "/?chapter=" + chapterId)
  }

  // delete admin directly added donation
  deleteDonation(id: any) {
    return this.http.delete(this.$baseURL +
      "/finance/sponser/" + id + "/")
  }

  // logout
  logout() {
    return this.http.get(this.$baseURL +
      "/accounts/logout/")
  }

  adminSettings(data: any) {
    let user = localStorage.getItem('user') || ''
    let users = user ? JSON.parse(user) : ''
    return this.http.patch(this.$baseURL + "/accounts/user/" + users?.id + "/", data)
  }

  // snackbar success/error messages
  customSnackBar(message: string, type: string, status: string) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: {
        message: message,
        type: type,
        status: status
      },
      duration: 5000,

    });
  }

  downloadFile(file: any, name: any) {
    -fetch(file, { method: 'GET' })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        saveAs(blob, name);
      })
      .catch((err) => {
        console.error('err: ', err);
      });
  }

  setLanguage(id: any, data: any) {
    return this.http.patch(this.$baseURL +
      "/accounts/user/" + id + "/", data)
  }


}
